@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .formulario {
    opacity: 0;
    animation: fadeIn 1s ease forwards;
  }
  
  .formulario.visible {
    opacity: 1;
  }