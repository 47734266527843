.leyendaTxtContainer {
  margin-top: 20px;
}

.leyendaTxtContainer a,
.leyendaTxtContainer a:hover {
  text-decoration: none;
  color: #000000c7;
  font-weight: 100;
  font-size: 10px;
  text-align: center;
}

.leyendaTxt {
  font-weight: 100;
  font-size: 15px;
}
