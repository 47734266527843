@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.contenedorCaja {
  opacity: 0;
  animation: fadeIn 1s ease forwards;
}

.contenedorCaja.visible {
  opacity: 1;
}



.contenedorCaja {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 600px;
}

.duxLogo {
  width: 35%;
  max-width: 300px;
  margin-top: -5%;
}

.cajaConHeaderAzul {
  background-color: white;
  border: 1px solid #ccccccbb;
  box-sizing: border-box;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.headerContent {
  background-color: #0763e7;
  width: 100%;
}

.cajaConHeaderAzul h2 {
  font-size: 20px;
  color: white;
  margin: 0;
  font-weight: 100;
  margin-left: 10px;
  padding: 5px 0;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.orderIdInput {
  color: black;
  background-color: #d4d4d480;
  text-align: center;
  font-size: 140%;
  caret-color: transparent;
  outline: none;
  border: none;
  margin: 25px auto;
  border-radius: 15px;
  padding: 2px 0;
  width: 100%;
  max-width: 400px;
  font-family: "Poppins";
}

.facturarButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid #ccccccbb;
}

.facturarButton {
  background-color: #0763e7;
  color: white;
  font-size: 20px;
  border: none;
  padding: 2px;
  margin: 10px;
  cursor: pointer;
  width: 20%;
  max-width: 400px;
  border-radius: 4px;
  font-family: "Poppins";
}

@media (max-width: 600px) {
  .duxLogo {
    width: 40%;
    max-width: none;
  }

  .orderIdInput {
    width: 90%;
    max-width: none;
  }

  .facturarButton {
    width: 35%;
  }
}
