body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  zoom: 95%;
}

.App {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  min-height: 100vh;
}

@media (max-width: 600px) {
  .duxLogo {
    width: 40%;
    max-width: none;
  }

  .orderIdInput {
    width: 90%;
    max-width: none;
  }

  .facturarButton {
    width: 35%;
  }
}
